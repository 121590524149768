import firebase, { COLLECTIONS, collection, db } from 'fb';
import { ReactNode, createContext, useContext, useEffect, useState, useRef } from 'react';
import { openDB } from 'idb';
import { FavoriteSongsDoc, Song, SongsMap, SubscriptionType } from 'types';
import { getChordSort } from 'components/Player/utils/multiplePlayerApps';
import { getFirstUrlPath, possibleFirstUrlPaths } from 'components/Player/utils/multiplePlayerApps';
import { initFavorites } from 'components/Player/utils/firebase';
import { useAuth, useIsSchoolAuth } from 'hooks/Auth';
import { userAccessToSong } from 'components/Player/utils/utils';

type SongsContextProps = {
  allSongs: Song[];
  getSearchFilters: () => SearchFilter | undefined;
  setSearchFilters: (
    genreSort: Record<string, unknown>,
    levelSort: Record<string, unknown>,
    searchTerm: string,
    toneArtSort: Record<string, unknown>,
    languageSort: Record<string, unknown>,
    favoriteSort: boolean,
    sortProp: string,
    nameOfSortButton: string,
    chordSort: {
      mustContain: {
        chord: number;
        minor: boolean;
      }[];
      cantContain: {
        chord: number;
        minor: boolean;
      }[];
    },
    shuffleOrder:
      | {
          [key: string]: number;
        }
      | undefined,
    onlySongsWithLyrics: boolean,
    allowExplicitSongs: boolean,
  ) => void;
  getAllSongs: () => Promise<Song[]>;
  getSongById: (id: string) => Promise<Song | string>;
  addToFavorites: (songId: string) => void;
  removeFromFavorites: (songId: string) => void;
  jumpInFilteredSongList: (currentSongId: string, jump: number, userSubscription?: SubscriptionType | undefined) => Promise<string>;
  setFilteredSongs: (songs: Song[]) => void;
};

type SearchFilter = {
  genreSort: Record<string, unknown>;
  levelSort: Record<string, unknown>;
  searchTerm: string;
  toneArtSort: Record<string, unknown>;
  languageSort: Record<string, unknown>;
  favoriteSort: boolean;
  sortProp: string;
  nameOfSortButton: string;
  chordSort: { mustContain: { chord: number; minor: boolean }[]; cantContain: { chord: number; minor: boolean }[] };
  shuffleOrder: { [key: string]: number } | undefined;
  onlySongsWithLyrics: boolean;
  allowExplicitSongs: boolean;
};

type SearchFilterMap = Map<string, SearchFilter>;

const SongsContext = createContext<SongsContextProps>({
  allSongs: [],
  getSearchFilters: () => undefined,
  setSearchFilters: (
    genreSort: Record<string, unknown>,
    levelSort: Record<string, unknown>,
    searchTerm: string,
    toneArtSort: Record<string, unknown>,
    languageSort: Record<string, unknown>,
    favoriteSort: boolean,
    sortProp: string,
    nameOfSortButton: string,
    chordSort: {
      mustContain: {
        chord: number;
        minor: boolean;
      }[];
      cantContain: {
        chord: number;
        minor: boolean;
      }[];
    },
    shuffleOrder:
      | {
          [key: string]: number;
        }
      | undefined,
    onlySongsWithLyrics: boolean,
    allowExplicitSongs: boolean,
  ) => {},
  getAllSongs: async () => [],
  getSongById: async () => 'does not exist',
  addToFavorites: (songId: string) => {},
  removeFromFavorites: (songId: string) => {},
  jumpInFilteredSongList: async (currentSongId: string, jump: number, userSubscription?: SubscriptionType | undefined) => '',
  setFilteredSongs: (songs: Song[]) => {},
});

const SongsProvider = ({ children }: { children: ReactNode }) => {
  const [auth, authIsLoading] = useAuth();
  const [isSchoolAuth, schoolAuthIsLoading] = useIsSchoolAuth();
  const [allSongs, setAllSongs] = useState<Song[]>([]);
  const [allSongsMap, setAllSongsMap] = useState<SongsMap>({});
  const filteredSongs = useRef<Song[]>([]);
  const [deletedSongIds, setDeletedSongIds] = useState<string[]>([]);
  const [favorites, setFavorites] = useState<{ [key: string]: Array<string> }>({});

  const allPlayerApps = possibleFirstUrlPaths as Array<string>;
  const daysBetweenCleanSongFetch = 30;

  useEffect(() => {
    if (!authIsLoading && auth && allSongs.length === 0) {
      getAllSongs(auth.uid);
    }
  }, [authIsLoading, auth, allSongs]);

  const getDefaultLyricsFilter = (playerApp: string) => {
    if (playerApp === 'singplayer') {
      return true;
    }
    if (playerApp === 'choirplayer') {
      return true;
    }
    return false;
  };

  const defaultSearchFilter = () => {
    const defaultSearchFilter = new Map();

    allPlayerApps.forEach((playerApp) => {
      defaultSearchFilter.set(playerApp, {
        favoriteSort: false,
        genreSort: {},
        languageSort: {},
        levelSort: {},
        searchTerm: '',
        toneArtSort: {},
        sortProp: '',
        nameOfSortButton: '',
        chordSort: getChordSort(playerApp),
        shuffleOrder: undefined,
        onlySongsWithLyrics: getDefaultLyricsFilter(playerApp),
        allowExplicitSongs: false,
      });
    });

    return defaultSearchFilter;
  };

  const searchFiltersRef = useRef<SearchFilterMap>(defaultSearchFilter());

  const setUpCache = () => {
    openDB('cache', 1, {
      upgrade(db) {
        db.createObjectStore('songCache');
      },
    });
  };

  useEffect(() => {
    setUpCache();
  }, []);

  const addToStorage = async (key: string, value: string) => {
    const db1 = await openDB('cache', 1);
    db1.put('songCache', value, key);
    db1.close();
  };

  const getFromStorage = async (key: string) => {
    const db1 = await openDB('cache', 1);
    const value = db1.get('songCache', key);
    return value;
  };

  const saveData = (songData: Array<Song>, deletedSongsId: Array<string>) => {
    addToStorage('deletedSongsId', JSON.stringify(deletedSongsId));
    addToStorage('cachedSongs', JSON.stringify(songData));
    const now = new Date(firebase.firestore.Timestamp.now().toDate());
    addToStorage('lastCachedSongDate', now.toString());
  };

  const searchById = (id: string, songsArray: Array<Song>) => {
    let start = 0;
    let end = songsArray.length - 1;

    while (start <= end) {
      const middle = Math.floor((start + end) / 2);

      if (songsArray[middle].id === id) {
        return middle;
      } else if (songsArray[middle].id < id) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }
    return -1;
  };

  const sortSongsArray = (songData: Array<Song>) => {
    const songsPlaceholder: Array<Song> = [...songData];
    songsPlaceholder.sort((a: Song, b: Song) => compare(a, b));
    return songsPlaceholder;
  };

  const compare = (a: Song, b: Song) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };
  const getAllDeletedSongsIdFromFirebase = async () => {
    const songsInThrashRef = await db.collection(COLLECTIONS.SONGS_TRASH).get();
    const songsInThrashId = songsInThrashRef.docs.map((snapshot) => {
      return snapshot.id;
    });

    const songsDeletedRef = await db.collection(COLLECTIONS.SONGS_DELETED).get();
    const songsDeletedhId = songsDeletedRef.docs.map((snapshot) => {
      return snapshot.id;
    });
    return songsInThrashId.concat(songsDeletedhId);
  };

  const getAllSongsFromFirebase = async () => {
    const songsRef = collection<Song>(COLLECTIONS.SONGS).orderBy('lastUpdated', 'desc').orderBy('title');

    return await recursivelyGetFirebaseBatches(songsRef);
  };
  const getSongDataFromFirebase = async () => {
    const songs = await getAllSongsFromFirebase();
    const newDeletedSongIds = await getAllDeletedSongsIdFromFirebase();

    return [songs, newDeletedSongIds] as [Song[], string[]];
  };

  const getFavoritesFromStorage = async (userId: string) => {
    const cachedFavoritesPlaceholder: string = (await getFromStorage('songIdFavorites_' + userId)) || '';
    let favoritesPlaceholder: { [key: string]: Array<string> } = {};
    if (cachedFavoritesPlaceholder !== '') {
      favoritesPlaceholder = JSON.parse(cachedFavoritesPlaceholder);
    }
    return favoritesPlaceholder;
  };

  const getFavoritesCachedDate = async (userId: string) => {
    const cachedDatePlaceholder: string = (await getFromStorage('lastCachedFavoritesDate_' + userId)) || '';
    if (cachedDatePlaceholder === '') {
      return undefined;
    }
    return new Date(cachedDatePlaceholder);
  };

  const getFavoritesFromFirebase = async (userId: string, date?: Date) => {
    let userFavoriteRef = collection<FavoriteSongsDoc>(COLLECTIONS.FAVORITES).where('userId', '==', userId);
    if (date !== undefined) {
      const timestamp = firebase.firestore.Timestamp.fromDate(date);
      userFavoriteRef = userFavoriteRef.where('lastUpdated', '>', timestamp);
    }
    const favoritesSnapshot = await userFavoriteRef.get();
    if (favoritesSnapshot.size === 0) {
      return {};
    }
    const favoritesDoc = favoritesSnapshot.docs[0].data();
    if (favoritesDoc) {
      return favoritesDoc.favoriteSongs;
    }
    return {};
  };

  const getFavorites = async (userId: string) => {
    if (Object.keys(favorites).length !== 0) {
      return favorites;
    }

    const cachedDate = await getFavoritesCachedDate(userId);
    let newFavorites = await getFavoritesFromFirebase(userId, cachedDate);

    if (Object.keys(newFavorites).length === 0) {
      newFavorites = await getFavoritesFromStorage(userId);
    }

    if (Object.keys(newFavorites).length === 0) {
      newFavorites = (await initFavorites(userId)) as { [player: string]: string[] };
    }

    for (const player of possibleFirstUrlPaths) {
      if (newFavorites[player] === undefined) {
        newFavorites[player] = [];
      }
    }

    saveFavoritesToLocalstorage(userId, newFavorites);
    setFavorites(newFavorites);

    return newFavorites;
  };

  const createSongsMapData = (songData: Array<Song>) => {
    const songMapPlaceholder: SongsMap = {};
    songData.forEach((song) => {
      songMapPlaceholder[song.id] = song;
    });
    return songMapPlaceholder;
  };

  const checkIfNeedsCleanFetch = async () => {
    // TODO(ks): REMOVE THIS HACKY SOLUTION AFTER 01.05.2025
    const cleanFetchFlag: string = (await getFromStorage('forceCleanFetchHasBeenRun')) || '';
    if (cleanFetchFlag.length < 11) {
      return true;
    }
    const cachedDate = (await getFromStorage('lastCleanCachedSongDate')) || '';
    if (cachedDate.length < 1) {
      return true;
    }
    const now = new Date(firebase.firestore.Timestamp.now().toDate());
    const hoursSinceLastCached = Math.abs(new Date(cachedDate).getTime() - now.getTime()) / 3600000;
    return hoursSinceLastCached >= daysBetweenCleanSongFetch * 24;
  };

  const getDataFromLocalStorage = async () => {
    const localStoragePlaceholder = (await getFromStorage('cachedSongs')) || '';
    const localStorageData = JSON.parse(localStoragePlaceholder);
    const cachedDatePlaceholder = (await getFromStorage('lastCachedSongDate')) || '';

    if (localStoragePlaceholder.length < 1 || localStorageData.length < 1 || cachedDatePlaceholder.length < 1) {
      return {
        allSongs: [] as string[],
        deletedSongIds: [] as string[],
        cachedDate: undefined,
      };
    }
    const cachedDeletedIdsString: string | null = await getFromStorage('deletedSongsId');
    const cachedDeletedIds: Array<string> = cachedDeletedIdsString ? JSON.parse(cachedDeletedIdsString) : [];

    const cachedDate = cachedDatePlaceholder ? new Date(cachedDatePlaceholder) || undefined : undefined;
    return { allSongs: localStorageData, deletedSongIds: cachedDeletedIds, cachedDate: cachedDate };
  };

  const getFirebaseBatch = async (
    query: firebase.firestore.Query<Song>,
    lastFetchedDoc: undefined | firebase.firestore.DocumentSnapshot<Song> = undefined,
    batchSize = 100,
  ) => {
    let localQuery = query;
    if (lastFetchedDoc !== undefined) {
      localQuery = localQuery.startAfter(lastFetchedDoc);
    }
    localQuery = localQuery.limit(batchSize);

    const documentSnapshot = await localQuery.get();

    const songs: Song[] = documentSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    let done = false;
    if (songs.length < batchSize) {
      done = true;
    }
    return { songs: songs, lastFetchedDoc: documentSnapshot.docs[documentSnapshot.docs.length - 1], done: done };
  };

  const recursivelyGetFirebaseBatches = async (
    query: firebase.firestore.Query<Song>,
    lastFetchedDoc: undefined | firebase.firestore.DocumentSnapshot<Song> = undefined,
  ) => {
    const { songs, lastFetchedDoc: newLastFetchedDoc, done } = await getFirebaseBatch(query, lastFetchedDoc);
    if (done) {
      return songs;
    }
    const previousSongs: Song[] = await recursivelyGetFirebaseBatches(query, newLastFetchedDoc);
    return [...songs, ...previousSongs];
  };

  const checkForNewChangesInFirebaseAndUpdateData = async (cachedSongs: Song[], cachedDeletedIds: string[], cachedDate?: Date) => {
    let dataHasChanged = false;
    let newSongList = [...cachedSongs];

    let songsRef: firebase.firestore.Query<Song> = collection<Song>(COLLECTIONS.SONGS);

    songsRef = songsRef.orderBy('lastUpdated', 'desc');
    if (cachedDate) {
      const cachedTimestamp = firebase.firestore.Timestamp.fromDate(cachedDate);
      songsRef = songsRef.where('lastUpdated', '>', cachedTimestamp);
    }
    songsRef = songsRef.orderBy('title');

    const downloadedSongs = await recursivelyGetFirebaseBatches(songsRef);
    if (downloadedSongs.length > 0) {
      dataHasChanged = true;
    }

    const downloadedSongsMap = Object.fromEntries(downloadedSongs.map((song) => [song.id, song]));
    const newSongListMap = Object.fromEntries(newSongList.map((song) => [song.id, song]));
    const allNewSongsListMap = { ...newSongListMap, ...downloadedSongsMap };

    newSongList = Object.values(allNewSongsListMap);
    let thrashSongsRef: firebase.firestore.Query<Song> = collection<Song>(COLLECTIONS.SONGS_TRASH);
    if (cachedDate) {
      const cachedTimestamp = firebase.firestore.Timestamp.fromDate(cachedDate);
      thrashSongsRef = thrashSongsRef.where('lastUpdated', '>', cachedTimestamp);
    }
    const thrashSongsDocs = await thrashSongsRef.get();
    const downloadedThrashSongs = thrashSongsDocs.docs.map((snapshot) => {
      return snapshot.id;
    });
    let deletedSongsRef: firebase.firestore.Query<Song> = collection<Song>(COLLECTIONS.SONGS_DELETED);
    if (cachedDate) {
      const cachedTimestamp = firebase.firestore.Timestamp.fromDate(cachedDate);
      deletedSongsRef = deletedSongsRef.where('lastUpdated', '>', cachedTimestamp);
    }
    const deletedSongsDocs = await deletedSongsRef.get();
    const downloadedDeletedSongs = deletedSongsDocs.docs.map((snapshot) => {
      return snapshot.id;
    });

    newSongList = sortSongsArray(newSongList);
    const songsToDelete = downloadedThrashSongs.concat(downloadedDeletedSongs);
    songsToDelete.map((deleteId) => {
      const deleteIndex = searchById(deleteId, newSongList);
      if (deleteIndex > -1) {
        newSongList.splice(deleteIndex, 1);
      }
    });

    const allDeletedSongs = songsToDelete.concat(cachedDeletedIds);

    if (songsToDelete.length > 0) {
      dataHasChanged = true;
    }

    return [newSongList, allDeletedSongs, dataHasChanged] as [Song[], string[], boolean];
  };

  const populateSongsWithFavorites = (songsArray: Array<Song>, userFavorites: Array<string>) => {
    const songsWithFavorites = songsArray;
    songsWithFavorites.forEach((song) => {
      song.favorite = false;
    });
    userFavorites.forEach((favoriteId) => {
      const favoriteIndex = searchById(favoriteId, songsArray);
      songsWithFavorites[favoriteIndex].favorite = true;
    });
    return songsWithFavorites;
  };

  const getAllSongs = async (userId: string) => {
    let allSongsToReturn: Song[] = [];
    if (allSongs.length > 0) {
      allSongsToReturn = allSongs;
    } else {
      let newDeleted: string[] = [];
      const needsCleanFetch = await checkIfNeedsCleanFetch();
      let cachedDate = undefined;
      if (!needsCleanFetch) {
        const dataFromLocalStorage = await getDataFromLocalStorage();
        allSongsToReturn = dataFromLocalStorage.allSongs;
        newDeleted = dataFromLocalStorage.deletedSongIds;
        cachedDate = dataFromLocalStorage.cachedDate;
      }
      if (needsCleanFetch || allSongsToReturn.length === 0) {
        const [songs, deleted] = await getSongDataFromFirebase();
        const now = new Date(firebase.firestore.Timestamp.now().toDate());
        await addToStorage('lastCleanCachedSongDate', now.toString());
        // TODO(ks): REMOVE THIS HACKY SOLUTION AFTER 01.05.2025
        await addToStorage('forceCleanFetchHasBeenRun', 'true_again2');
        allSongsToReturn = songs;
        newDeleted = deleted;
        saveData(songs, deleted);
      } else {
        const [songs, deleted, dataHasChanged] = await checkForNewChangesInFirebaseAndUpdateData(allSongsToReturn, newDeleted, cachedDate);
        allSongsToReturn = songs;
        newDeleted = deleted;
        if (dataHasChanged) {
          saveData(allSongsToReturn, newDeleted);
        }
      }
      const newMap = createSongsMapData(allSongsToReturn);
      setDeletedSongIds(newDeleted);
      setAllSongsMap(newMap);
    }
    const localFavorites = await getFavorites(userId);

    allSongsToReturn = populateSongsWithFavorites(allSongsToReturn, localFavorites[getFirstUrlPath()] || []);
    setAllSongs(allSongsToReturn);
    return allSongsToReturn;
  };

  const applyFilters = (songs: Song[], filters?: SearchFilter) => {
    return songs;
  };

  const setSearchFilters = (
    genreSort: Record<string, unknown>,
    levelSort: Record<string, unknown>,
    searchTerm: string,
    toneArtSort: Record<string, unknown>,
    languageSort: Record<string, unknown>,
    favoriteSort: boolean,
    sortProp: string,
    nameOfSortButton: string,
    chordSort: { mustContain: { chord: number; minor: boolean }[]; cantContain: { chord: number; minor: boolean }[] },
    shuffleOrder: { [key: string]: number } | undefined,
    onlySongsWithLyrics: boolean,
    allowExplicitSongs: boolean,
  ) => {
    const player = getFirstUrlPath();
    searchFiltersRef.current.set(player, {
      genreSort,
      levelSort,
      searchTerm,
      toneArtSort,
      languageSort,
      favoriteSort,
      sortProp,
      nameOfSortButton,
      chordSort,
      shuffleOrder,
      onlySongsWithLyrics,
      allowExplicitSongs,
    });
  };

  const getSearchFilters = () => {
    return searchFiltersRef.current.get(getFirstUrlPath());
  };

  const getSongById = async (userId: string, songId: string) => {
    let map = allSongsMap;
    if (allSongs.length === 0) {
      const songs = await getAllSongs(userId);
      map = createSongsMapData(songs);
    }
    const song = map[songId];
    if (song) {
      return song;
    } else if (deletedSongIds.includes(songId)) {
      return 'deleted';
    }
    return 'does not exist';
  };

  const getAllSongsOfUser = async () => {
    if (auth) {
      return getAllSongs(auth.uid);
    }
    return [];
  };

  const getSongByIdWithUser = async (songId: string) => {
    if (auth) {
      return getSongById(auth.uid, songId);
    }
    return 'does not exist';
  };

  const jumpInFilteredSongListLocal = async (
    userId: string,
    currentSongId: string,
    jump: number,
    userSubscription: SubscriptionType | undefined = undefined,
  ) => {
    let songs = filteredSongs.current || [];
    if (songs.length < 1) {
      songs = await getAllSongs(userId);
    }

    const currentIndex = songs.findIndex((song: Song) => song.id === currentSongId);
    if (currentIndex === -1) {
      return songs[0].id;
    }

    let newIndex = (currentIndex + jump + 2 * songs.length) % songs.length;

    if (userSubscription !== undefined) {
      let song = songs[newIndex];
      let access = userAccessToSong(song, getFirstUrlPath(), userSubscription, isSchoolAuth || schoolAuthIsLoading, false).access;
      const increment = jump > 0 ? 1 : -1;
      while (!access) {
        newIndex = (newIndex + increment + songs.length) % songs.length;
        song = songs[newIndex];
        access = userAccessToSong(song, getFirstUrlPath(), userSubscription, isSchoolAuth || schoolAuthIsLoading, false).access;
      }
    }
    return songs[newIndex].id;
  };

  const jumpInFilteredSongList = async (currentSongId: string, jump: number, userSubscription: SubscriptionType | undefined = undefined) => {
    if (auth) {
      const userId = auth.uid;
      return jumpInFilteredSongListLocal(userId, currentSongId, jump, userSubscription);
    }
    return '';
  };

  const saveFavoritesToLocalstorage = async (userId: string, favorites: { [key: string]: Array<string> }) => {
    await addToStorage('songIdFavorites_' + userId, JSON.stringify(favorites));
    const now = new Date(firebase.firestore.Timestamp.now().toDate());
    await addToStorage('lastCachedFavoritesDate_' + userId, now.toString());
  };

  const addToFavoritesInternal = (userId: string, songId: string) => {
    const playerApp = getFirstUrlPath();
    if (favorites[playerApp] === undefined) {
      favorites[playerApp] = [];
    }
    favorites[playerApp].push(songId);

    // sets favorite prop to true on song in songlist
    const favoriteIndex = searchById(songId, allSongs);
    allSongs[favoriteIndex].favorite = true;

    const updateObj: { [key: string]: string | firebase.firestore.FieldValue } = {
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      userId: userId,
    };
    updateObj['favoriteSongs.' + playerApp] = firebase.firestore.FieldValue.arrayUnion(songId);

    // add to fb
    db.collection(COLLECTIONS.FAVORITES)
      .doc(userId)
      .update(updateObj)
      .then(() => {
        // save to localstorage
        saveFavoritesToLocalstorage(userId, favorites);
      });
  };

  const addToFavorites = (songId: string) => {
    const userId = auth?.uid;
    if (userId) {
      addToFavoritesInternal(userId, songId);
    }
  };

  const removeFromFavoritesInternal = (userId: string, songId: string) => {
    // removes from variable
    const playerApp = getFirstUrlPath();
    const index = favorites[playerApp].indexOf(songId);
    if (index !== -1) {
      favorites[playerApp].splice(index, 1);
    }

    // sets favorite prop to flase on song in songlist
    const favoriteIndex = searchById(songId, allSongs);
    allSongs[favoriteIndex].favorite = false;

    const updateObj: { [key: string]: string | firebase.firestore.FieldValue } = {
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      userId: userId,
    };
    updateObj['favoriteSongs.' + playerApp] = firebase.firestore.FieldValue.arrayRemove(songId);

    // removes from fb
    db.collection(COLLECTIONS.FAVORITES)
      .doc(userId)
      .update(updateObj)
      .then(() => {
        // save to localstorage
        saveFavoritesToLocalstorage(userId, favorites);
      });
  };

  const removeFromFavorites = (songId: string) => {
    const userId = auth?.uid;
    if (userId) {
      removeFromFavoritesInternal(userId, songId);
    }
  };

  const setFilteredSongs = (songs: Song[]) => {
    filteredSongs.current = songs;
  };

  return (
    <SongsContext.Provider
      value={{
        setFilteredSongs: setFilteredSongs,
        allSongs: allSongs,
        getSearchFilters: getSearchFilters,
        setSearchFilters: setSearchFilters,
        getAllSongs: getAllSongsOfUser,
        getSongById: getSongByIdWithUser,
        addToFavorites: addToFavorites,
        removeFromFavorites: removeFromFavorites,
        jumpInFilteredSongList: jumpInFilteredSongList,
      }}>
      {children}
    </SongsContext.Provider>
  );
};

const useSongs = () => {
  const context = useContext(SongsContext);
  return context.allSongs;
};

const useFilteredSongs = () => {
  const context = useContext(SongsContext);
  return {
    getSearchFilters: context.getSearchFilters,
    setSearchFilters: context.setSearchFilters,
    jumpInFilteredSongList: context.jumpInFilteredSongList,
    setFilteredSongs: context.setFilteredSongs,
  };
};

const useGetSongs = () => {
  const context = useContext(SongsContext);
  return { getAllSongs: context.getAllSongs, getSongById: context.getSongById };
};

const useSongFavorites = () => {
  const context = useContext(SongsContext);
  return { addToFavorites: context.addToFavorites, removeFromFavorites: context.removeFromFavorites };
};
export { SongsProvider, useSongs, useFilteredSongs, useGetSongs, useSongFavorites };
